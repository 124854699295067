<template>
  <p v-html="misionx">{{$t(`${misionx}`)}}</p>
</template>

<script>
export default {
  props: {
    misionx: { type: String, required: true },
  },
};
</script>

<style scoped>

</style>