<template>
  <section id="team" class="our_team section-padding">
    <div class="container" v-if="agents.length > 0">
      <div class="row text-center justify-content-center">
        <!-- <div class="section-title text-center wow zoomIn">
          <h2>{{ $t("pages.agent_detail.title") }}</h2>
          <div></div>
        </div> -->
        <Preloader v-if="!agents" />

        <AgentCard
          v-else
          v-for="(item, i) in agents"
          :key="item.folio"
          :name="item.folio"
          :index="i"
          :agent="item"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import Preloader from "@/components/layout/Preloader.vue";
import AgentCard from "@/components/layout/AgentCard.vue";
export default {
  components: {
    Preloader,
    AgentCard,
  },
  computed: {
    ...mapGetters({
      agents: "_getTeam",
      error: "_getError",
    }),
  },
  created() {
    this.$store.dispatch("getTeam");
  },
};
</script>

<style scoped>

.justify-content-center{
  justify-content: center !important;
}
</style>