<template>
    
    <section class="error-page section-padding">
        <NotFound code="404" />
    </section>
</template>

<script>
import NotFound from '@/components/layout/NotFound.vue';
export default {
  components:{
    NotFound
  }
}
</script>