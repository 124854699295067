<template>
  <section class="template_property">
    <div class="container">
      <div class="row justify-content-center">
        <Preloader v-if="!properties" />
        <PropertyCard
          v-else
          v-for="(item, i) in properties"
          :key="item.folio"
          :name="item.folio"
          :index="i"
          :item="item"
        />
      </div>
    </div>
  </section>
</template>

<script>
import Preloader from "@/components/layout/Preloader.vue";
import { mapGetters } from "vuex";
import NotResults from "@/components/layout/NotFound";
import PropertyCard from "@/components/properties/PropertyCard.vue";
import SearchForm from "@/components/search/SearchForm.vue";
export default {
  components: {
    PropertyCard,
    NotResults,
    Preloader,
    SearchForm,
  },
  data() {
    return {
      codeShareProperties: 0,
    };
  },
  computed: {
    ...mapGetters({
      properties: "_getProperties",
      error: "_getError",
    }),
  },
  created() {
    if (this.$route.params.codeShareProperties) {
      this.codeShareProperties = this.$route.params.codeShareProperties;
    }
    this.$store.dispatch("getProperties", this.codeShareProperties);
  },
};
</script>
