<template>
  <section id="contact" class="contact-us section-padding">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-6 col-sm-6 col-xs-12">
          <ContactForm />
        </div>
        <!-- END COL  -->
        <div class="col-md-6 col-sm-6 col-xs-12">
          <div class="single_contact_two">
            <div class="single_address" v-if="info.address!=0">
              <i class="fas fa-map-marker-alt"></i>
              <p class="p-center">{{ info.address }}</p>
            </div>
            <div class="single_address">
              <i class="fa fa-phone color-two"></i>
              <p class="p-center">{{ info.phone }}</p>
            </div>
            <div class="single_address single_address_mbnone">
              <i class="fa fa-envelope color-three"></i>
              <p class="p-center">
                <a :href="'mailto:' + info.reserveemail">
                  {{ info.reserveemail }}</a
                >
              </p>
            </div>
          </div>
        </div>
        <!-- END COL  -->
      </div>
      <!-- END ROW -->
    </div>
    <!--- END CONTAINER -->
  </section>
</template>

<script>
import ContactForm from "@/components/layout/ContactForm.vue";
import { mapGetters } from "vuex";
export default {
  name: "app",
  components: {
    ContactForm,
  },
  computed: {
    ...mapGetters({
      info: "_getInfoCompany",
    }),
  },
};
</script>

<style scoped>
.p-center{
  padding: 20px 0 ;
  font-size: 13px;
}

.single-address{
  height: 95px;
}
</style>