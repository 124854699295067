var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"id":"homeCarousel"}},[(_vm.items.length > 0)?_c('VueAgile',{ref:"main",staticClass:"contain-slide",attrs:{"fade":true,"dots":false}},[_vm._l((_vm.items),function(slide,i){return _c('div',{key:slide.folio,staticClass:"slide",attrs:{"index":i}},[_c('img',{staticClass:"card-img image-slide-back",attrs:{"src":slide.largefile,"alt":slide.alt}}),_c('div',{staticClass:"card-img-overlay slide-img-overlay cc_pointer",on:{"click":function($event){slide.type != 1
            ? _vm.goToProperty(slide.folio, slide.propertyNameEs)
            : _vm.goToDevelopment(slide.folio, slide.developmentNameEs)}}},[_c('img',{staticClass:"image-slide",attrs:{"src":slide.largefile,"alt":slide.alt}}),_c('div',{staticClass:"row align-items-center justify-content-center text-center up"},[_c('div',{staticClass:"col-md-11"},[_c('h1',{staticClass:"mb-2 cc_pointer",on:{"click":function($event){slide.type != 1
                  ? _vm.goToProperty(slide.folio, slide.propertyNameEs)
                  : _vm.goToDevelopment(slide.folio, slide.developmentNameEs)}}},[_vm._v(" "+_vm._s(slide.type != 1 ? slide.propertyNameEs : slide.developmentNameEs)+" ")]),_c('p',{staticClass:"mb-5"},[_c('i',{staticClass:"fa fa-map-marker red1",attrs:{"aria-hidden":"true"}}),_c('span',{staticClass:"h2 text-success font-weight-bold"},[_vm._v(" "+_vm._s(slide.city ? ((slide.city) + ", " + (slide.state)) : slide.state)+" ")])]),_c('a',{staticClass:"btn-color btn-white btn-outline-white py-3 px-5 rounded-0 btn-2",on:{"click":function($event){slide.type != 1
                  ? _vm.goToProperty(slide.folio, slide.propertyNameEs)
                  : _vm.goToDevelopment(slide.folio, slide.developmentNameEs)}}},[_vm._v(" "+_vm._s(_vm.$t("components.home_carousel.details"))+" ")])])]),(_vm.info.logo)?_c('div',{staticClass:"watermark cc_pointer",style:('background-image:url(' + _vm.info.logo + '); '),on:{"click":function($event){slide.type != 1
              ? _vm.goToProperty(slide.folio, slide.propertyNameEs)
              : _vm.goToDevelopment(slide.folio, slide.developmentNameEs)}}}):_vm._e()])])}),_c('template',{slot:"prevButton"},[_c('i',{staticClass:"fas fa-chevron-left"})]),_c('template',{slot:"nextButton"},[_c('i',{staticClass:"fas fa-chevron-right"})])],2):_c('Preloader')],1)}
var staticRenderFns = []

export { render, staticRenderFns }