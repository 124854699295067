<template>
  <div>
    <Preloader v-if="!dev" />
    <section v-else class="blog_post">
      <!-- BANNER -->
      <Banner
        :pageTitle="
          activeLocale == 'es' || !dev.developmentNameEn
            ? dev.developmentNameEs
            : dev.developmentNameEn
        "
      />

      <div class="container">
        <div class="row">
          <!-- SECTION LEFT -->
          <div class="col-md-8 col-sm-12 col-xs-12">
            <!-- CAROUSEL -->
            <DetailCarousel :items="dev.images" />

            <!-- BEST INFORMATION -->
            <div class="blog_post_left">
              <div class="row">
                <div class="home_blog">
                  <div class="col-md-4">
                    <div class="single_blog_title">
                      <h4 v-if="dev.folio != '0'">
                        <span> # </span> {{ dev.folio }}
                      </h4>
                      <h5 v-if="dev.address != '0'">{{ dev.address }}</h5>
                    </div>
                  </div>
                  <div class="col-md-8">
                    <div class="single_blog_content">
                      <!-- BEST INFORMATION -->
                      <ul class="features row justify-content-center">
                        <li class="col-lg-4" v-if="dev.parking != 0">
                          <span>
                            {{ $t("pages.property_detail.feature_parking") }}
                            <br />
                          </span>
                          <span>
                            <b
                              >{{ dev.pkFrom }}
                              {{ dev.pkTo ? `- ${dev.pkTo}` : "" }}</b
                            >
                          </span>
                        </li>

                        <li class="col-lg-4" v-if="dev.bedFrom || dev.bedTo">
                          <span class="property-specs">
                            {{ $t("pages.property_detail.feature_bedrooms") }}
                          </span>
                          <br />
                          <span class="property-specs-number">
                            <b
                              >{{ dev.bedFrom }}
                              {{ dev.bedTo ? `- ${dev.bedTo}` : "" }}</b
                            >
                          </span>
                        </li>

                        <li class="col-lg-4" v-if="dev.bathFrom || dev.bathTo">
                          <span class="property-specs">
                            {{ $t("pages.property_detail.feature_bathrooms") }}
                          </span>
                          <br />
                          <span class="property-specs-number">
                            <b
                              >{{ dev.bathFrom }}
                              {{ dev.bathTo ? `- ${dev.bathTo}` : "" }}</b
                            >
                          </span>
                        </li>

                        <li class="col-lg-4" v-if="dev.m2cFrom || dev.m2cTo">
                          <span class="property-specs">
                            {{ $t("pages.property_detail.feature_area_cons") }}
                          </span>
                          <br />
                          <span class="property-specs-number">
                            <b>
                              {{ dev.m2cFrom }}
                              {{ dev.m2cTo ? `- ${dev.m2cTo}` : "" }} m
                              <sup>2</sup></b
                            >
                          </span>
                        </li>
                        <li class="col-lg-4" v-if="dev.towers != 0">
                          <span>
                            {{ $t("pages.development_detail.towers") }}
                            <br />
                          </span>
                          <span>
                            <b
                              >{{ dev.towers}}</b
                            >
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- PRICES & BOTON-->
            <div class="blog_post_left">
              <div class="row">
                <div class="col-7">
                  <div v-if="dev.priceFrom != 0" class="col-md-12 text-center">
                    <h2 class="color">
                      {{ $t("pages.development_detail.price_from") }}
                    </h2>
                    <strong>
                      {{ dev.priceFrom }}
                    </strong>
                  </div>
                </div>
                <div class="col-5">
                  <button
                    type="button"
                    @click="goTo('master-plan')"
                    class="go-masterplan-button btn btn-primary"
                  >
                    {{ $t("pages.development_detail.masterplan_title") }}
                  </button>
                </div>
              </div>
            </div>
            <br />

            <!-- DESCRIPTION -->
            <div class="blog_post_left up-desc mt-0">
              <div class="section-title text-center wow zoomIn">
                <h2>{{ $t("pages.property_detail.description_prop") }}</h2>
                <div></div>
              </div>
              <div v-if="dev.descriptionEs != 0">
                <h3 class="des-title">
                  {{ $t("pages.property_detail.language_description_es") }}
                </h3>
                <pre>
                      <p>{{ dev.descriptionEs }}</p>
                  </pre>
              </div>
              <div v-if="dev.descriptionEn != 0">
                <h3 class="des-title">
                  {{ $t("pages.property_detail.language_description_en") }}
                </h3>
                <pre>
                      <p>{{ dev.descriptionEn }}</p>
                  </pre>
              </div>
            </div>

            <!-- AMENITIES -->
            <div class="blog_post_left">
              <div
                v-if="dev.amenities || dev.services || dev.maintenance_includes"
                class="section-title text-center wow zoomIn"
              >
                <h2>{{ $t("pages.property_detail.amenities_prop") }}</h2>
                <div></div>
              </div>
              <div
                v-if="dev.amenities || dev.services || dev.maintenance_includes"
              >
                <div class="container" v-if="dev.amenities">
                  <div class="row mb-3">
                    <h4 class="h4 text-black">
                      {{ $t("pages.property_detail.amenities_interior") }}
                    </h4>
                  </div>
                  <div class="row">
                    <p
                      class="amenidades col-md-4"
                      v-for="(item, i) in dev.amenities"
                      :index="i"
                      :key="i"
                    >
                      <i class="fas fa-check"></i>
                      {{ item }}
                    </p>
                  </div>
                </div>

                <div class="container" v-if="dev.services">
                  <div class="row mb-3">
                    <h4 class="h4 text-black">
                      {{ $t("pages.property_detail.amenities_exterior") }}
                    </h4>
                  </div>
                  <div class="row">
                    <p
                      class="amenidades col-md-4"
                      v-for="(item, i) in dev.services"
                      :index="i"
                      :key="i"
                    >
                      <i class="fas fa-check"></i>
                      {{ item }}
                    </p>
                  </div>
                </div>

                <div class="container" v-if="dev.maintenance_includes">
                  <div class="row mb-3">
                    <h4 class="h4 text-black">
                      {{ $t("pages.property_detail.amenities_maintenance") }}
                    </h4>
                  </div>
                  <div class="row">
                    <p
                      class="amenidades col-md-4"
                      v-for="(item, i) in dev.maintenance_includes"
                      :index="i"
                      :key="i"
                    >
                      <i class="fas fa-check"></i>
                      {{ item }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- SECTION RIGHT -->
          <div class="col-md-4 col-sm-12 col-xs-12 p-5">
            <!-- CONTACT -->
            <div class="categories wow fadeInRight">
              <h4 class="blog_sidebar_title">
                {{ $t("pages.property_detail.contact_us") }}
              </h4>
              <div class="text" style="text-align: center">
                <p v-if="dev.agent.cellphone != 0" class="mb-0">
                  <span> {{ $t("pages.property_detail.agent_phone") }} : </span>
                  <a
                    class="contact"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Contactar en WhatsApp"
                    target="_blank"
                    :href="
                      `https://api.whatsapp.com/send?text=${$t(
                        'pages.property_detail.share_whats_message_agent'
                      )}: ${url}&phone=52${dev.agent.cellphone}`
                    "
                  >
                    {{ dev.agent.cellphone }}
                  </a>
                </p>
                <p v-if="dev.agent.email != 0" class="mb-4 ">
                  <span>{{ $t("pages.property_detail.agent_email") }} :</span>
                  <a class="contact"> {{ dev.agent.email }}</a>
                </p>
              </div>
              <ContactForm />
            </div>

            <!-- DOWNLOAD -->
            <div class="video_post wow fadeInRight mt-3">
              <h4 class="blog_sidebar_title">
                {{ $t("pages.property_detail.share_title") }}
              </h4>
              <div>
                <h4 class=" text-black">Flyers Español</h4>
                <div class="tag">
                  <a
                    v-if="dev.flyer.flyerEs4"
                    target="_blank"
                    :href="dev.flyer.flyerEs4"
                  >
                    <i class="fa fa-file-image-o"></i> 4 Fotos
                  </a>
                  <a
                    v-if="dev.flyer.flyerEs10"
                    target="_blank"
                    :href="dev.flyer.flyerEs10"
                  >
                    <i class="fa fa-file-image-o"></i> 10 Fotos</a
                  >
                </div>
              </div>
              <br />
              <div>
                <h4 class=" text-black">English Flyers</h4>
                <div class="tag justify-content-center">
                  <a
                    v-if="dev.flyer.flyerEn4"
                    target="_blank"
                    :href="dev.flyer.flyerEn4"
                  >
                    <i class="fa fa-file-image-o"></i> 4 Fotos
                  </a>
                  <a
                    v-if="dev.flyer.flyerEn10"
                    target="_blank"
                    :href="dev.flyer.flyerEn10"
                  >
                    <i class="fa fa-file-image-o"></i> 10 Fotos
                  </a>
                </div>
              </div>
            </div>

            <!-- SHARE -->
            <div class="categories wow fadeInRight mb-5">
              <h4 class="blog_sidebar_title">
                {{ $t("pages.property_detail.share_prop") }}
              </h4>
              <ul class="social-networks">
                <li>
                  <a
                    :href="
                      `https://www.facebook.com/sharer/sharer.php?u=${url}`
                    "
                    target="_blank"
                    ><i class="fab fa-facebook" aria-hidden="true"></i
                  ></a>
                </li>
                <li>
                  <a
                    title="Compartir por Twitter"
                    :href="`https://twitter.com/home?status=${url}`"
                    target="_blank"
                  >
                    <i class="fab fa-twitter"></i>
                  </a>
                </li>
                <li>
                  <a
                    title="Compartir por WhatsApp"
                    :href="
                      `https://api.whatsapp.com/send?text=${$t(
                        'pages.property_detail.share_whats_message_public'
                      )}: ${url}`
                    "
                    target="_blank"
                    ><i class="fab fa-whatsapp"></i
                  ></a>
                </li>
              </ul>
            </div>
            <br />

            <!-- LOCATION -->
            <div class="mb-5 mt-5" v-if="dev.latitude || dev.longitude">
              <div class="col-md-12 no-gutters mb-5">
                <div class="col-12">
                  <h4 class="blog_sidebar_title">
                    {{ $t("pages.property_detail.map_title") }}
                  </h4>
                </div>
                <div class="col-12 ">
                  <GoogleMap :mapLat="dev.latitude" :mapLng="dev.longitude" />
                </div>
              </div>
            </div>

            <!-- VIDEO -->
            <div class="mb-5 mt-5">
              <div v-if="dev.video">
                <div class="col-md-12 no-gutters mt-3">
                  <div class="col-12">
                    <h2 class="text-center mb-5">
                      {{ $t("pages.property_detail.video") }}
                    </h2>
                  </div>
                  <div class="col-12">
                    <iframe
                      width="100%"
                      height="315"
                      :src="dev.video"
                      frameborder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    >
                    </iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <!-- Mapper area -->
          <section id="master-plan" class="row ">
            <div class="col-md-12 ">
              <div class="bg-white">
                <!-- Mapper -->
                <section class="row  master-plan" v-if="dev.blueprints">
                  <div class="col-md-12 text-center">
                    <div class="section-title text-center wow zoomIn"
                    >
                      <h2>{{ $t("pages.development_detail.masterplan_title") }}</h2>
                      <div></div>
                    </div>
                    <div class="mt-5 image-master-plan">
                      <a
                        class="example-image-link animated-link-dark"
                        :href="dev.masterplan[0].image"
                        data-lightbox="example-set"
                        :data-title="dev.developmentNameEs"
                      >
                        <img
                          class="cover"
                          :src="dev.masterplan[0].image"
                          :alt="`masterplan-${dev.developmentNameEs}`"
                          srcset=""
                        />
                      </a>
                    </div>
                    <div class="mt-5 mb-3">
                      <button
                        type="button"
                        @click="goToSection()"
                        class="btn btn-primary py-3 px-5 mb-2 rounded-2 go-masterplan-button"
                      >
                        {{
                          $t(
                            "pages.development_detail.masterplan_section_button"
                          )
                        }}
                      </button>
                    </div>
                  </div>
                </section>
                <!--section class="row mb-5" v-if="dev.blueprints">
                      <div class=" col-md-12 no-gutters">
                        <div class="row">
                          <h4 class="h4 text-black"> 
                            {{$t("pages.development_detail.mapper_title")}}
                          </h4>
                        </div>
                        <div class="row references">
                          <div class="col">
                            <div class="color-reference status-1"></div>
                            <span>Disponible</span>
                          </div>
                          <div class="col">
                            <div class="color-reference status-2"></div>
                            <span>Reservado</span>
                          </div>
                          <div class="col">
                            <div class="color-reference status-3"></div>
                            <span>No Disponible</span>
                          </div>
                        </div>

                        <div >
                          <ul class="nav nav-tabs mt-4" id="mapperTab" role="tablist">
                            <li class="nav-item" v-for="(mapSection,i) in dev.blueprints" :key="i" :index="mapSection[i]">
                              <a :class="['nav-link', {'active':i==0} ]" :id="`section-tab-${i}`" data-toggle="tab" :href="`#section${i}`" role="tab" aria-controls="home" aria-selected="true">Sección {{i+1}}</a>
                            </li>
                          </ul>

                          <div class="tab-content mt-3" id="mapperTabContent">
                            <div v-for="(mapSection,i) in dev.blueprints" :key="i" :index="mapSection[i]" :id="'section'+i" :class="['tab-pane fade', {'show active':i==0}]" role="tabpanel" :aria-labelledby="`section-tab-${i}`">
                              <Mapper :mapper="mapSection"/> 
                              <div id="myresult" class="img-zoom-result"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section-->
              </div>
            </div>
          </section>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Banner from "@/components/layout/Banner.vue";
import Preloader from "@/components/layout/Preloader.vue";
import ContactForm from "@/components/layout/ContactForm.vue";
import SearchForm from "@/components/search/SearchForm.vue";
import GoogleMap from "@/components/layout/GoogleMap.vue";
import Mapper from "@/components/developments/Mapper.vue";
import DetailCarousel from "@/components/carousel/DetailCarousel.vue";
export default {
  components: {
    Banner,
    Preloader,
    ContactForm,
    SearchForm,
    GoogleMap,
    Mapper,
    DetailCarousel,
  },
  data() {
    return {
      folio: "",
      agentId: 0,
      url: "",
      amenities: [],
    };
  },
  computed: {
    ...mapGetters({
      dev: "_getDevelopment",
    }),
    activeLocale() {
      return this.$i18n.locale;
    },
    format(val) {
      let value = val.dev.priceFrom;
      //Formateo de decimales
      let decimalPrice = value.lastIndexOf(".");
      //Extaracción de moneda
      let currentPriceM = value.indexOf("MXN");
      let currentPriceU = value.indexOf("USD");

      // Precio formateado value.substr(currentPrice)
      let formatPrice = value.substr(0, decimalPrice) + " ";
      if (currentPriceM != "-1") {
        formatPrice += value.substr(currentPriceM);
      } else {
        formatPrice += value.substr(currentPriceU);
      }
      return "$" + formatPrice.slice(1);
    },
  },
  created() {
    this.folio = this.$route.params.folio;

    let data = {
      folio: this.folio,
    };
    this.$store.dispatch("getDevelopment", data);
    this.url = this.$store.state.URL_SITE + this.$route.path;
  },
  methods: {
    goTo(ref) {
      document
        .getElementById(ref)
        .scrollIntoView({ block: "nearest", behavior: "smooth" });
    },
    goToSection() {
      let folio = this.folio;
      this.$router.push({ name: "DevelopmentSections", params: { folio } });
    },
  },
};
</script>

<style scoped>
/* COLUMN 8 */
/* GENERAL */

.single_blog_title h4 span {
  color: #ce7c66 !important;
  margin: 0;
}

.up-desc {
  margin-top: 10px !important;
}
.color {
  color: #ce7c66 !important;
}

.features {
  font-family: "Montserrat", sans-serif;
  color: #2a2a2a;
  list-style: none;
  font-size: 13px !important;
}

.not-price {
  color: #616161 !important;
  text-decoration: line-through;
}

strong {
  font-size: 20px;
}

pre {
  white-space: pre-wrap;
  height: auto;
  overflow: hidden;
  background: transparent;
  border-color: transparent;
  color: #000;
  font-family: "Montserrat",sans-serif, Open Sans, sans-serif;
  font-size: 15px;
  text-align: justify;
}

.des-title {
  padding-left: 15px;
  text-align: justify;
}

/* amenities */
.amenidades {
  font-family: "Montserrat",sans-serif, Open Sans, sans-serif;
      font-size: 13px;
}
.amenidades i {
  color: #ce7c66;
}

.h4 {
  text-align: left;
  padding-left: 50px;
  font-size: 20px;
}

.contact {
  color: #373737 !important;
}

.contact:hover {
  color: #ce7c66 !important;
}

/* banners flyers */

.banner {
  display: block;
  margin-top: 20px !important;
  list-style-type: none;
  padding: 0;
  overflow: hidden;
  text-align: center;
  margin-bottom: 10px;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

.buttons-content {
  list-style-type: none;
  padding: 0;
  overflow: hidden;
  text-align: center;
}
.button-flyer {
  display: block;
  color: #373737;
  width: auto;
  border: 2px solid #ce7c66;
  border-radius: 4px;
  padding: 7px;
  margin: 5px;
  text-decoration: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.button-flyer:hover {
  background: #ce7c66 !important;
  color: rgb(255, 255, 255);
  outline: none !important;
}

.tag {
  display: block;
  margin-top: 10px !important;
  list-style-type: none;
  padding: 0;
  overflow: hidden;
  text-align: center;
  margin-bottom: 50px;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

.social-networks li {
  float: left !important;
  text-align: center !important;
  width: 3em;
  font-size: 25px;
  display: inline;
}

.categories ul {
  text-align: center !important;
  margin-left: 80px;
}

.text {
  font-size: 13px;
}

.mt-5 {
  margin-top: 40px !important;
}

.go-masterplan-button {
  background: #001248 !important;
  border: 1px solid #001248 !important;
  color: #fff;
  font-size: 12px;
  padding: 10px 30px;
  text-transform: uppercase;
  transition: all 0.2s ease 0s;
}

.go-masterplan-button:hover {
  background: transparent !important;
  color: #001248!important;
}

.image-master-plan img {
  width: 100%;
  cursor: zoom-in;
}

</style>
