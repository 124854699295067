<template>
  <div class="search_bar section-padding">
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <form v-on:submit.prevent="searchProps">
            <div class="row  justify-content-center">
              <!-- KEYWORD -->
              <div class="col-md-3">
                <div class="form-group">
                  <div class="form-field">
                    <input
                      v-model="search.keyword"
                      type="text"
                      class="form-control"
                      :placeholder="$t('layout.search_form.keyword_input')"
                    />
                  </div>
                </div>
              </div>

              <!-- PROPERTY TYPE -->
              <div v-if="!search.searchDevelopments" class="col-md-3">
                <div class="form-group">
                  <div class="form-field">
                    <i class="fas fa-sort-down"></i>
                    <select
                      name="select-type"
                      id="select-type"
                      v-model="search.type"
                      class="form-control d-block rounded-0"
                    >
                      <option selected value="">
                        {{ $t("layout.search_form.property_type_input") }}
                      </option>
                      <option
                        v-for="(type, i) in !search.searchDevelopments
                          ? input.types
                          : input.typesDevs"
                        :key="i"
                        :index="i"
                        :value="type.typepropertyid"
                      >
                        {{ type.typepropertyspa }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <!-- OPERATION -->
              <div class="col-md-3" v-if="!search.searchDevelopments">
                <div class="form-group">
                  <div class="form-field">
                    <i class="fas fa-sort-down"></i>
                    <select
                      name="select-city"
                      id="select-city"
                      v-model="search.operation"
                      class="form-control d-block rounded-0"
                    >
                      <option selected value="">
                        {{ $t("layout.search_form.operation_input") }}
                      </option>
                      <option value="1">
                        {{ $t("layout.search_form.option_sold") }}
                      </option>
                      <option value="2">
                        {{ $t("layout.search_form.option_rent") }}
                      </option>
                      <option value="3">
                        {{ $t("layout.search_form.option_vacation") }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <!-- PRICE MIN -->
              <div class="col-md-3">
                <div class="form-group">
                  <div class="form-field">
                    <money
                      type="text"
                      class="form-control d-block rounded-0"
                      step="any"
                      name="minprice"
                      v-model="search.minPrice"
                      v-bind="moneyMin"
                    ></money>
                  </div>
                </div>
              </div>

              <!-- PRICE MAX -->
              <div class="col-md-3">
                <div class="form-group">
                  <div class="form-field">
                    <money
                      type="text"
                      class="form-control d-block rounded-0"
                      step="any"
                      name="maxprice"
                      v-model="search.maxPrice"
                      v-bind="moneyMax"
                    ></money>
                  </div>
                </div>
              </div>

              <!-- LOCATION -->
              <div class="col-md-3">
                <div class="form-group">
                  <div class="form-field">
                    <i class="fas fa-sort-down"></i>
                    <select
                      name="select-city"
                      id="select-city"
                      class="form-control"
                      v-model="search.city"
                    >
                      <option selected value="">
                        {{ $t("layout.search_form.location_input") }}
                      </option>
                      <option
                        v-for="(city, i) in !search.searchDevelopments
                          ? input.cities
                          : input.citiesDevs"
                        :key="i"
                        :index="i"
                        :value="city.city"
                      >
                        {{ city.city }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <!-- OPERATION -->
              <div  v-if="nameRoute!='Agent'" class="col-md-3 justify-content-center">
                <label class="label-switch"
                  >{{ $t("layout.search_form.type_search") }}
                </label>
                <label
                  class="label-switch switchBtn label-switch-responsive label-center"
                >
                  <input v-model="search.searchDevelopments" type="checkbox" />
                  <div class="slide round"></div>
                </label>
              </div>

              <!-- BUTTON -->
              <div class="col-md-3 btn-center">
                <div class="form-group">
                  <div class="form-field">
                    <label for=""></label>
                    <button
                      v-if="!sending"
                      :disabled="sent"
                      type="submit"
                      class="col-md-12 btn btn-lg btn-contact-bg"
                    >
                      {{ $t("layout.search_form.button") }}
                    </button>
                    <div v-else>
                      <Preloader />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Money } from "v-money";
import Preloader from "@/components/layout/Preloader.vue";
export default {
  components: {
    Money,
    Preloader,
  },
  data() {
    return {
      search: {
        operation: "",
        type: "",
        typeDevs: "",
        city: "",
        keyword: "",
        minPrice: "",
        maxPrice: "",
        urbanization: "",
        beds: "",
        baths: "",
        parking: "",
        searchDevelopments: false,
      },
      searching: false,
      moneyMin: {
        thousand: ",",
        prefix: "P. Min: $",
        precision: 0,
        masked: false,
      },
      moneyMax: {
        thousand: ",",
        prefix: "P. Max: $",
        precision: 0,
        masked: false,
      },
      advanced: true,
    };
  },
  computed: {
    ...mapGetters({
      input: "_getInputs",
    }),
    nameRoute() {
      return this.$route.name;
    },
  },
  methods: {
    searchProps() {
      this.searching = true;
      let searchForm = this.search;
      this.$store.dispatch("getResults", searchForm);
      if (!this.search.searchDevelopments) {
        if (this.$route.name != "Results")
          this.$router.push({ name: "Results" });
      } else {
        if (this.$route.name != "ResultsDevs")
          this.$router.push({ name: "ResultsDevs" });
      }

      this.searching = false;
    },
    changeForm() {
      for (let i in this.search) {
        if (i != "searchDevelopments") this.search[i] = "";
      }
    },
  },
  created() {
    this.$store.dispatch("getInputs");
    console.log(this.$route.name);
    if (
      this.$route.name == "Developments" ||
      this.$route.name == "ResultsDevs"
    ) {
      this.search.searchDevelopments = true;
    }
  },
};
</script>

<style scoped>
.search_bar {
  font-family: "Montserrat", sans-serif;
}

/* SWITCH */

.switchBtn {
  position: relative;
  display: inline-block;
  width: 65px;
  height: 34px;
}
.switchBtn input {
  display: none;
}
.slide {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  padding: 8px;
  color: #fff;
}
.slide:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 5px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
input:checked + .slide {
  background-color: #001550;
  padding-left: 40px;
}
input:focus + .slide {
  box-shadow: 0 0 1px #001550;
}
input:checked + .slide:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
  left: 10px;
}
.label-switch {
  margin-right: 30px;
  font-weight: 300 !important;
  margin-bottom: 0 !important;
  padding: 14px 15px;
  font-size: 12px;
}
.slide.round {
  border-radius: 34px;
}
.slide.round:before {
  border-radius: 50%;
}

.label-center {
  top: 12px;
}

/*  */
.form-control {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  box-shadow: none;
  border-color: #e4e4e4;
  color: #74777c;
  background-color: #fff !important;
  height: 41px !important;
  margin-bottom: 10px;
  margin-top: 10px;
  font-size: 14px;
}

button:hover {
  background: transparent !important;
  color: #001550;
  font-size: 14px;
  padding: 10px 30px;
  text-transform: uppercase;
  transition: all 0.2s ease 0s;
}

button {
  background: #001550 !important;
  border: 1px solid #001550 !important;
  color: #fff;
  font-size: 14px;
  padding: 10px 30px;
  text-transform: uppercase;
  transition: all 0.2s ease 0s;
}

.form-field i {
  display: block;
  position: absolute;
  top: 43%;
  color: #74777c;
  right: 16px;
  line-height: 0;
  font-size: 15px;
  transform: translateY(-50%);
}

@media only screen and (max-width: 991px) {
  .form-field i {
    right: 50px;
  }
}

.btn-center {
  margin: 10px 0;
}
</style>
