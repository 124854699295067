<template>
			<div class="container not-found">
				<div v-if="code !=208"  class="row">				
					<div class="col-md-6 col-md-offset-3 col-sm-12 col-xs-12 text-center">
						<div class="error_page">
							<h1><span v-for="(number, i) in splitCode" :key="number" :index="i">{{number}}</span></h1>
							<h2>{{$t(`components.error_section.${code}.title`)}}</h2>
                            <br>
							<router-link to="/" class="btn-contact-bg">{{$t("layout.not_found.go_home")}}</router-link>
						</div>
					</div><!--- END COL -->	
				</div><!--- END ROW -->
        <div v-else class="row">
          <div class="error_page">
            <h2>{{$t(`components.error_section.${code}.title`)}}</h2>
            <img v-if="nameRoute=='Agents'" class="text-center my-3" src="/images/agentg.png" alt="no-agents-yet"> 
            <img v-if="nameRoute=='Agent'" class="text-center my-3"  src="/images/no-propsg.png" alt="no-properties-agent">
          </div>
          <br>
      </div>
			</div><!--- END CONTAINER -->
</template>

<script>
export default {
  props:{
    code:{
      typeo:String,
      require:true
    }
  },
  computed:{
    splitCode(){
      return this.code.split("")
    },
    nameRoute() {
      return this.$route.name;
    },
  }
}
</script>
<style scoped>
  .not-found img{
    width: 150px;
  }
</style>