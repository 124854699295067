<template>
  <select name="" id="locale" v-model="$i18n.locale" >
    <option
      v-for="locale in locales"
      :key="locale.key"
      :value="locale.key"
      :style="`background-image:url(${locale.icon});`"
    >
      {{ locale.label }}
    </option>
  </select>
</template>

<script>
export default {
  data() {
    return {
      locales: [
        { key: "es", label: "Español" },
        { key: "en", label: "English" },
      ],
    };
  },
  watch: {
    "$i18n.locale": {
      handler(locale) {
        localStorage.setItem("locale", locale);
      },
    },
  },
};
</script>

<style>
#locale {
    color: #333  !important;
    font-family: "Montserrat",sans-serif;
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;
    background-color: transparent;
    border: none;
}

#locale:hover{
    color: #001550  !important;
}

#locale:focus{
  outline: none;
  
}
@media only screen and (max-width:1199px) {
  #locale {
    padding:7px 0px;
    color: #212529;
    line-height: 1.4285em;
    letter-spacing: .0em;
    font-size: 12px; 
  }
}
</style>