<template>
  <div>
    <footer class="footer-top">
      <div class="container">
        <div class="row">
          <div class="col-md-12 text-center">
            <div class="footer_social">
              <ul class="p-0">
                <li v-if="info.fb">
                  <a
                    data-toggle="tooltip"
                    data-placement="top"
                    title=""
                    :href="info.fb"
                    data-original-title="Facebook"
                    ><i class="fa fa-facebook"></i
                  ></a>
                </li>
                <li v-if="info.tw">
                  <a 
                    data-toggle="tooltip"
                    data-placement="top"
                    title=""
                    :href="info.tw"
                    data-original-title="Twitter"
                    ><i class="fa fa-twitter"></i
                  ></a>
                </li>
                <li v-if="info.instagram">
                  <a 
                    data-toggle="tooltip"
                    data-placement="top"
                    title=""
                    :href="info.instagram"
                    data-original-title="Google Plus"
                    ><i class="fa fa-instagram"></i
                  ></a>
                </li>
                <li v-if="info.linkedin">
                  <a 
                    data-toggle="tooltip"
                    data-placement="top"
                    title=""
                    :href="info.linkedin"
                    data-original-title="Linkedin"
                    ><i class="fa fa-linkedin"></i
                  ></a>
                </li>
                <li v-if="info.youtube">
                  <a 
                    data-toggle="tooltip"
                    data-placement="top"
                    title=""
                    :href="info.youtube"
                    data-original-title="Youtube"
                    ><i class="fa fa-youtube-play"></i
                  ></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row footer-padding">
          <!-- LOGO -->
          <div class="col-md-2 col-sm-4 col-xs-12">
            <div class="single_footer text-center">
              <router-link to="/">
                <img
                  v-if="info.logo"
                  class="logo"
                  :src="info.logo"
                  :alt="info.alt"
                />
                <h3 v-else>{{ info.companyName }}</h3>
              </router-link>
            </div>
          </div>
          <!--- MENU -->
          <div class="col-md-4 col-sm-4 col-xs-12">
            <div class="single_footer ">
              <h4>{{ $t("layout.branding.menu_title") }}</h4>
              <div class="footer_contact">
                  <ul class="col-menu p-0">
                    <li>
                      <router-link to="/">
                        {{ $t("layout.nav.home") }}</router-link
                      >
                    </li>
                    <li>
                      <router-link to="/desarrollos">{{
                        $t("layout.nav.developments")
                      }}</router-link>
                    </li>
                    <li>
                      <router-link to="/nosotros">{{
                        $t("layout.nav.about.title")
                      }}</router-link>
                    </li>
                    <li>
                      <router-link to="/propiedades">{{
                        $t("layout.nav.properties")
                      }}</router-link>
                    </li>
                    <li>
                      <router-link to="/agentes">{{
                        $t("layout.nav.agents")
                      }}</router-link>
                    </li>
                    <li>
                      <router-link to="/contacto">{{
                        $t("layout.nav.contact")
                      }}</router-link>
                    </li>
                  </ul>
              </div>
            </div>
          </div>
          <!-- CONTACT -->
          <div class="col-md-6 col-sm-4 col-xs-12">
            <div class="single_footer">
              <h4>{{ $t("layout.branding.contact_title") }}</h4>
              <div class="footer_contact">
                <ul class="list p-0">
                  <li v-if="info.address">
                    <i class="fas fa-map-marker-alt"></i>
                    <span>3{{ info.address }}</span>
                  </li>

                  <li v-if="info.phone">
                    <i class="fa fa-phone"></i>
                    <a
                      class="contact-links"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Contáctenos"
                      :href="'tel:+52' + info.phone"
                      target="_blank"
                    >
                      <span> {{ info.phone }}</span>
                    </a>
                  </li>
                  <li  v-if="info.reserveemail" >
                    <i class="fa fa-envelope"></i> <span>{{info.reserveemail}}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <!--- END COL -->
        </div>
        <!--- END ROW -->
      </div>
      <!--- END CONTAINER -->
    </footer>
    <footer class="footer">
      <div class="container">
        <div class="row text-center">
          <div
            class="col-md-6 col-sm-6 col-xs-6 wow zoomIn"
            style="visibility: visible; animation-name: zoomIn;"
          >
            <p class="footer_copyright">{{info.companyName}}<sup>&copy;</sup>  {{year}} | {{ $t("layout.branding.copyright") }}</p>
          </div>
          <div class="col-md-6 col-sm-6 col-xs-6 wow zoomIn">
          <p class="footer_copyright">
            Powered with <i class="far fa-heart"></i> & <i class="fas fa-mug-hot"></i> by <a href="https://www.immocrm360.com.mx/" target="_blank">IMMO CRM 360<sup>&copy;</sup></a>
          </p>
        </div>
          <!--- END COL -->
        </div>
        <!--- END ROW -->
      </div>
      <!--- END CONTAINER -->
    </footer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      year: "",
    };
  },
  computed: {
    ...mapGetters({
      info: "_getInfoCompany",
    }),
  },
  created() {
    console.log("create footer");
    let d = new Date();
    this.year = d.getFullYear();
  },
};
</script>

<style scoped>
.footer_contact ul li{
  display:inherit;
}
.logo {
  width: 90%;
}
.logo_foot {
  margin-top: 5%;
}
.contact-links {
  flex-wrap: wrap;
  overflow-wrap: anywhere;
}
@media only screen and (max-width: 991px) {
  .logo {
    width: auto;
    max-height: 190px;
  }
}
.list li{
  margin-bottom: 0;
}

a{
  color: #747474
}

.col-menu{
  columns: 2;
}

@media only screen and (max-width: 480px){
.menu-col {
    margin-top: 30px;
    columns: 2;
}
}

@media only screen and (max-width: 991px){
.logo {
    width: auto;
    max-height: 90px;
}
}
</style>
