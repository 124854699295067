<template>
  <div>
    <div class="container">
      <div class="row mb-5">
        <DevelopmentCard
          v-for="(item, i) in developments"
          :key="item.folio"
          :name="item.folio"
          :index="i"
          :item="item"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DevelopmentCard from "@/components/developments/DevelopmentCard.vue";
export default {
  components: {
    DevelopmentCard,
  },
  computed: {
    ...mapGetters({
      developments: "_getDevelopments",
      error: "_getError",
    }),
  },
  created() {
    this.$store.dispatch("getDevelopments");
  },
};
</script>
