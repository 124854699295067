<template>
  <p v-html="valuesx">{{$t(`${valuesx}`)}}</p>
</template>

<script>
export default {
  props: {
    valuesx: { type: String, required: true },
  },
};
</script>

<style scoped>

</style>