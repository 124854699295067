<template>
  <section class="template-property section-padding about-us">
    <div class="container">
        <div class="row ">
          <div class="col-md-6 col-sm-12 col-xs-12 text-center">
            <div class="about-us-content">
              <h2>{{ info.companyName }}</h2>
              <p v-if="info.about_us == 0 & info.about_us_eng == 0 || info.about_us == 0"
              >
              {{$t("pages.about.company.description")}}
            </p>
            <p v-else class="jump-line"
              >
              <About 
                :aboutx="activeLocale == 'es' || !info.about_us_eng
                ? info.about_us	 : info.about_us_eng"
                />
            </p>
            </div>
            <div class="about-us-content">
              <h2>{{ $t("pages.about.mision.title") }}</h2>
                <p v-if="info.our_mission == 0 & info.our_mission_eng == 0" >
                  {{$t("pages.about.mision.description")}}
                </p>
                <p v-else class="jump-line">
                  <Mision 
                    :misionx="activeLocale == 'es' || !info.our_mission_eng
                    ? info.our_mission	 : info.our_mission_eng"
                    />
                </p>
            </div>
            <div class="about-us-content">
              <h2>{{ $t("pages.about.vision.title") }}</h2>
              <p v-if="info.our_vision == 0 & info.our_vision_eng == 0">
                    {{$t("pages.about.vision.description")}}
                </p>
                <p v-else class="jump-line">
                  <Vision 
                    :visionx="activeLocale == 'es' || !info.our_vision_eng
                    ? info.our_vision	 : info.our_vision_eng"
                    />
                </p>
            </div>
          <!-- <p class="quote">

            <span v-if="info.our_slogan == 0 & info.our_slogan_eng == 0" 
              > {{$t("pages.about.slogan")}}</span
            >
            <span v-else class="jump-line">
              <Slogan 
                :sloganx="activeLocale == 'es' || !info.our_slogan_eng
                ? info.our_slogan	 : info.our_slogan_eng"
                />
            </span>
          </p> -->
        <div class="about-us-content mt-5">
          <a href="/contacto"> {{ $t("pages.about.contact-button") }}</a>
        </div>
          </div>
          <div class="col-md-6 col-sm-12 col-xs-12">
            <div class="about_img text-center">
              <img
                v-if="info.logoClean"
                class="logo mt-3 mb-0"
                :src="info.logoClean"
                alt="logo_web"
              />
            </div>
            <div class="about-us-content">
              <h2>{{ $t("pages.about.values.title") }}</h2>
              <p v-if="info.our_values == 0 & info.our_values_eng == 0" >
                <ul >
                  <li>
                    {{$t("pages.about.values.example_one.description")}}

                  </li>
                  <li>{{$t("pages.about.values.example_two.description")}}</li>
                </ul>
              </p>
              <p v-else class="jump-line">
                  <Values 
                    :valuesx="activeLocale == 'es' || !info.our_values_eng
                    ? info.our_values	 : info.our_values_eng"
                    />
              </p>
            </div>
          </div>
        </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import About from "@/components/about/About.vue";
import Slogan from "@/components/about/Slogan.vue";
import Mision from "@/components/about/Mision.vue";
import Vision from "@/components/about/Vision.vue";
import Values from "@/components/about/Values.vue";
export default {
    components:{
    About,
    Slogan,
    Mision,
    Vision,
    Values,
  },
  name: "app",
  computed: {
    ...mapGetters({
      info: "_getInfoCompany",
    }),
    activeLocale() {
        return this.$i18n.locale;
    },
  },
};
</script>

<style scoped>
.logo {
  display: inline-block;
  max-width: 25% !important;
  margin-top: auto;
}
.logo img {
  margin-top: 30%;
  margin-bottom: 20px;
  margin-left: -10px;
  max-width: 20px;
  max-height: 30px;
}

.about_img {
  margin-bottom: 30px;
}

.about-us-content {
  padding: 0 30px;
  align-content: center !important;
}

.jump-line{
  word-wrap: break-word;
  white-space: pre-wrap;
}

</style>
